export const assetsPlaceholder = [
    { "name": "Toad #Mr", "image_url": "https://digital.qorbiworld.com/eggs/toad.png" },
    { "name": "Qor-X Pass", "image_url": "https://digital.qorbiworld.com/qorx/Qor_X_v3.jpg" },
    { "name": "Monkey #9", "image_url": "https://digital.qorbiworld.com/eggs/Monkey.png" },
    { "name": "Primal Toxic", "image_url": "https://digital.qorbiworld.com/one+tap+skins/Eva_Toxic.jpg" },
    { "name": "Octopus #P", "image_url": "https://digital.qorbiworld.com/eggs/octopus.png" },
    { "name": "Octopus 2466", "image_url": "https://digital.qorbiworld.com/eggs/Octopus_Silver.gif" },
    { "name": "Panther #Pink", "image_url": "https://digital.qorbiworld.com/eggs/Panter.png" },
    { "name": "Dragon #24", "image_url": "https://digital.qorbiworld.com/eggs/Dragon.png" },
    { "name": "Turtle #o", "image_url": "https://qorbabies.mypinata.cloud/ipfs/QmUP6Bx8vKPqDYZ7JEgeX9PFX2Jx8hdUyT14V8SLXuGAf2" },
    { "name": "Celestial Camellia Dark Basic", "image_url": "https://digital.qorbiworld.com/one+tap+skins/Crimson_Edge_Celestial_Camellia_Dark_Basic.jpg" },
    { "name": "Chameleon #281", "image_url": "https://qorbabies.mypinata.cloud/ipfs/Qmf49jWrtJD72teJAhn5ecy9g8bzRegicy9NF333sQn7BU" },
    { "name": "Turtle #250", "image_url": "https://qorbabies.mypinata.cloud/ipfs/QmaqTUAMjTVnNjYZxCnpxRiSvvVoAPhR3B6ZNHmNxtyX7q" },
    { "name": "Jaguar #s", "image_url": "https://qorbabies.mypinata.cloud/ipfs/QmbwxPFiSedvqT6Jz4edqVZHFMusRbkCTHkg8cLLAAdoCr" },
    { "name": "Celestial Swan Light Camo", "image_url": "https://digital.qorbiworld.com/one+tap+skins/Hagal_Celestial_Swan_Light_Camo.jpg" },
    { "name": "Mouse #2", "image_url": "https://digital.qorbiworld.com/eggs/Mouse.png" },
    { "name": "Armadillo #17", "image_url": "https://digital.qorbiworld.com/eggs/Armadillo.png" },
    { "name": "Chameleon #h", "image_url": "https://qorbabies.mypinata.cloud/ipfs/QmPW2MifmKzLvyWmKnaBGrpVin6juo2gxDbRbS6nxaEpWc" },
    { "name": "Octopus 4785", "image_url": "https://digital.qorbiworld.com/eggs/Octopus_Gold.gif" },
    { "name": "Jaguar #143", "image_url": "https://qorbabies.mypinata.cloud/ipfs/QmXhgZkZxRAQsePEknxZqdYWExtfMKbMyFcYEJMQyS8qC5" },
    { "name": "Octopus 1879", "image_url": "https://qorbabies.mypinata.cloud/ipfs/QmaNtB2Zv9MWGx97knLH6Ffw27tAXEKTUrzPJWJ6WeMWU5" },
    { "name": "Tiger #o", "image_url": "https://digital.qorbiworld.com/eggs/tiger.png" },
    { "name": "Primal Scorch", "image_url": "https://digital.qorbiworld.com/one+tap+skins/Lancer_Scorch.jpg" },
    { "name": "Rabbit #94", "image_url": "https://digital.qorbiworld.com/eggs/Rabbit.png" },
    { "name": "Primal Scorch", "image_url": "https://digital.qorbiworld.com/one+tap+skins/Clint-44_Scorch.jpg" },
    { "name": "Qor-X Pass", "image_url": "https://digital.qorbiworld.com/qorx/Qor_X_v2.jpg" },
    { "name": "Fox #35", "image_url": "https://digital.qorbiworld.com/eggs/Fox.png" },
    { "name": "Chameleon #48", "image_url": "https://qorbabies.mypinata.cloud/ipfs/QmeqG32tktnw5Yk8JAjwstVb5uC5kupfWF1eez8XHFdy9q" },
    { "name": "Primal Arctic", "image_url": "https://digital.qorbiworld.com/one+tap+skins/QuickHammer_Artic.jpg" },
    { "name": "Wolf #F", "image_url": "https://digital.qorbiworld.com/eggs/Wolf.png" },
    { "name": "Primal Stonehalla", "image_url": "https://digital.qorbiworld.com/one+tap+skins/QuickHammer_Stonehalla.jpg" },
    { "name": "Sabertooth #5", "image_url": "https://digital.qorbiworld.com/eggs/Sabertooth.png" },
    { "name": "Qor-X Pass", "image_url": "https://digital.qorbiworld.com/qorx/Qor_X_v4.jpg" },
    { "name": "Octopus #r", "image_url": "https://digital.qorbiworld.com/eggs/octopus.png" },
    { "name": "Celestial Swan Light Camo", "image_url": "https://digital.qorbiworld.com/one+tap+skins/Lancer_Celestial_Swan_Light_Camo.jpg" },
    { "name": "Hyena #3", "image_url": "https://digital.qorbiworld.com/eggs/Hyena.png" },
    { "name": "Snake #8", "image_url": "https://digital.qorbiworld.com/eggs/snake.png" },
    { "name": "Eagle #i", "image_url": "https://digital.qorbiworld.com/eggs/Eagle.png" },
    { "name": "Celestial Swan Light Camo", "image_url": "https://digital.qorbiworld.com/one+tap+skins/Hagal_Celestial_Swan_Light_Camo.jpg" },
    { "name": "Primal Toxic", "image_url": "https://digital.qorbiworld.com/one+tap+skins/Crimson_Edge_Toxic.jpg" },
    { "name": "Celestial Swan Light Camo", "image_url": "https://digital.qorbiworld.com/one+tap+skins/Crimson_Edge_Celestial_Camellia_Dark_Basic.jpg" },
    { "name": "Cat #t", "image_url": "https://digital.qorbiworld.com/eggs/Cat.png" },
    { "name": "Qor-X Pass", "image_url": "https://digital.qorbiworld.com/qorx/Qor_X_v5.jpg" },
    { "name": "Pterodactyl #29", "image_url": "https://digital.qorbiworld.com/eggs/Pteranodon.png" },
    { "name": "Octopus #1021", "image_url": "https://digital.qorbiworld.com/eggs/octopus.png" },
    { "name": "Primal Toxic #o", "image_url": "https://digital.qorbiworld.com/one+tap+skins/Crimson_Edge_Toxic.jpg" },
    { "name": "Jaguar #761", "image_url": "https://qorbabies.mypinata.cloud/ipfs/QmVD3fAdXmFy53S6EdWvcLFzoBc6bgXTVgBP17yhXU6XTJ" },
]
import React, { useEffect, useState } from 'react';
import { checkout } from '@imtbl/sdk';

const checkoutSDK = new checkout.Checkout();

const ZkMarketplace = () => {
  const [widget, setWidget] = useState(undefined);
  const [provider, setProvider] = useState(null);

  const getWalletData = async () => {
   
    console.log(`Connected wallet address:-`);

  }

  useEffect(() => {
    (async () => {
      const factory = await checkoutSDK.widgets({
        config: {
          language: 'en',
          theme: checkout.WidgetTheme.DARK,
          WALLET: {
            showNetworkMenu: false,
            showDisconnectButton: true,
          },
        },
      });

      const checkoutWidget = factory.create(checkout.WidgetType.IMMUTABLE_COMMERCE);
      setWidget(checkoutWidget);
    })();
  }, []);

  useEffect(() => {
    if (!widget) return;

    widget.mount('mount-point', {
      flow: checkout.CommerceFlowType.CONNECT,
    });

    widget.addListener(
      checkout.CommerceEventType.SUCCESS,
      (payload) => {
        const { type, data } = payload;
        if (type === checkout.CommerceSuccessEventType.CONNECT_SUCCESS) {
          console.log('Connected to ', data.walletProviderName);
          setProvider(data.provider);
          console.log(data);
          widget.unmount();
          getWalletData();
        }
      }
    );

    widget.addListener(
      checkout.CommerceEventType.FAILURE,
      (payload) => {
        const { type, data } = payload;
        if (type === checkout.CommerceFailureEventType.CONNECT_FAILED) {
          console.log('Failed to connect', data.reason);
        }
      }
    );

    widget.addListener(checkout.CommerceEventType.CLOSE, () => {
      widget.unmount();
    });

    return () => {
      widget.removeListener(checkout.CommerceEventType.SUCCESS);
      widget.removeListener(checkout.CommerceEventType.DISCONNECTED);
      widget.removeListener(checkout.CommerceEventType.CLOSE);
    };
  }, [widget]);

  return (
    <div className="marketplace-container">
      <div id="mount-point" />
      {provider && (
        <div>
          <h2>Welcome to zkEVM Marketplace</h2>
          {/* <p>Connected Wallet: {provider?.accounts[0]}</p> */}
        </div>
      )}
    </div>
  );
};

export default ZkMarketplace;

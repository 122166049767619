import axios from 'axios';

const baseUrl = "https://api.qorbiworld.com"
// const devUrl = "https://dev-api.qorbiworld.com"
// const localUrl = "http://localhost:4001"

export const getQorbiUser = async (identifier) => {
    const user = await axios.get(`${baseUrl}/sphere-one/user/passport/${identifier}`)
    return user.data;
};

export const getQorbiUserSafe = async (identifier) => {
    const user = await axios.get(`${baseUrl}/sphere-one/user/passport-a954aa/${identifier}`)
    return user.data;
};

export const availableOpenlootMigration = async () => {
    const res = await axios.get(`${baseUrl}/migrate/available-openloot-zkevm`)
    return res.data;
};

export const createQorbiUser = async (userData) => {
    const response = await axios.post(`${baseUrl}/sphere-one/add-user`, userData)
    return response;
};

export const addQorbToPool = async (qorbData) => {
    const response = await axios.post(`${baseUrl}/stake/add-qorb-pool`, qorbData)
    return response.data;
};

export const updateQorbiUser = async (userData) => {
    const response = await axios.patch(`${baseUrl}/sphere-one/update-user`, userData);
    return response;
};

export const migrateAssetsFromOpenLoot = async (authToken, wallet) => {
    try {
        const response = await axios.post(
            `${baseUrl}/migrate/openloot-zkevm`,
            {
                walletAddress: wallet,
            },
            {
                headers: {
                    Authorization: `${authToken}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error migrating assets:", error);
        throw error;
    }
};

export const getOpenLootUserData = async (code, userId, redirectUri) => {
    const response = await axios.patch(`${baseUrl}/open-loot/user-data-by-token`, {
        code,
        redirectUri,
        userId,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
        }
    });
    return response.data;
};

export const trackMigration = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}/migrate/status/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error tracking migration status:", error);
        throw error;
    }
};

export const checkAndSpend = async (email, otpCode) => {
    try {
        await axios.patch(`${baseUrl}/sphere-one/check-and-spend/${email}/${otpCode}`);
        return true;
    } catch (error) {
        console.error("Error whitelisting:", error);
        throw error;
    }
};

export const requestOtp = async (email) => {
    try {
        const response = await axios.post(`${baseUrl}/sphere-one/request-otp/${email}`);
        return response.data.message === "OTP sent successfully" && true
    } catch (error) {
        console.error("Error requesting OTP:", error);
        throw error;
    }
};

export const getOpenLootAssets = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}/open-loot/user-nfts/${id}`);
        const assets = response.data;

        for (let asset of assets) {
            if (asset.metadata?.name === "Qorb Premier Badge") {
                try {
                    const checkMintResponse = await axios.get(
                        `${baseUrl}/v1/check-badge-able-to-mint?tokenId=${asset.id}`
                    );
                    asset.isAble = checkMintResponse.data.isAble === true;
                } catch (checkError) {
                    console.error(`Error checking if badge can mint for asset ID ${asset.id}:`, checkError);
                    asset.isAble = false;
                }
            } else {
                asset.isAble = false;
            }
        }

        return assets;
    } catch (error) {
        console.error("Error fetching Open Loot assets:", error);
        throw error;
    }
};
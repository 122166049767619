import React, { useEffect, useState, useContext } from 'react';
import { bundlesArray, Toast, linkAddress } from '../utils/Global';
import { useParams, useNavigate } from 'react-router-dom';
import { ERC721TokenType, Link } from "@imtbl/imx-sdk";
import { Context } from "../context/WalletContext";
import { Button } from "../components/Button";
import Popup from "../components/Popup";
import ForgeLoader from "../assets/svg/ForgeLoader";
const immutableService = require('../context/ImmutableService');

const MyBundles = () => {

    const { id } = useParams();
    const { state } = useContext(Context);

    const bundleId = parseInt(id)
    const navigate = useNavigate();

    const currentItem = bundlesArray[bundleId - 1];
    const [carouselData, setCarouselData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedNFTs, setSelectedNFTs] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const processCarouselData = (skinsNfts) => {
        const carouselEntries = Object.entries(currentItem.carousel);
        const carouselData = carouselEntries.map(([name, image]) => {
            const skinsAmount = skinsNfts.filter(nft => nft.name === name).length;
            return { name, image, skinsAmount };
        });
        setCarouselData(carouselData);
        const uniqueNFTs = {};
        const result = [];
        skinsNfts.forEach(nft => {
            if (!uniqueNFTs[nft.name]) {
                uniqueNFTs[nft.name] = nft;
                result.push({
                    token_id: nft.token_id,
                    token_address: nft.token_address,
                    name: nft.name
                });
            }
        });
        setSelectedNFTs(result)
    };

    const handlePrevious = () => {
        const previousItemId = bundleId > 1 ? bundleId - 1 : bundlesArray.length;
        navigate(`/my-bundles/${previousItemId}`);
    };

    const handleNext = () => {
        const nextItemId = bundleId < bundlesArray.length ? bundleId + 1 : 1;
        navigate(`/my-bundles/${nextItemId}`);
    };

    const handleBurnSkins = () => {
        setShowPopup(true)
    };

    const verify = async () => {
        const link = new Link(linkAddress);
        setShowPopup(false);
        setShowLoader(true)
        const array = [];
        const nfts = [];
        selectedNFTs.forEach((element) => {
            array.push(element.token_id);
            nfts.push({
                type: ERC721TokenType.ERC721,
                tokenId: element.token_id,
                tokenAddress: element.token_address,
                toAddress: "0xFd2B3962F91b326409872Cd179EA0dfB328327F1",
            });
        });
        let response = await link.batchNftTransfer(nfts);
        if (response.result.length > 0) {
            sendSkinFromForge()
        }
    };

    const sendSkinFromForge = async () => {
        const maxRetries = 6;

        const tradeSkins = async (retryCount = 0) => {
            const bundleName = currentItem.name.split(' ').slice(0, -1).join(' ');
            try {
                const tradeRequest = await fetch('https://api.qorbiworld.com/v1/nft/trade-bundle-by-pet', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        bundleName,
                        walletAddress: state?.userWallet?.string,
                    }),
                });

                if (!tradeRequest.ok) {
                    throw new Error('Failed to complete trade');
                }
                const tradeResponse = await tradeRequest.json();
                const followId = tradeResponse.followId;
                const checkStatus = async (currentRetry = 0) => {
                    let status = '';
                    while (status !== 'completed' && status !== 'failed') {
                        if (currentRetry >= maxRetries) {
                            return tradeSkins(retryCount + 1);
                        }
                        await new Promise(resolve => setTimeout(resolve, 5000));
                        const followRequest = await fetch(`https://api.qorbiworld.com/v1/nft/trade-bundle-by-pet/${followId}`);
                        if (!followRequest.ok) {
                            throw new Error('Failed to fetch follow status');
                        }
                        const followResponse = await followRequest.json();
                        status = followResponse.data.status;
                        currentRetry++;
                    }
                    if (status === 'completed') {
                        setTimeout(() => {
                            navigate('/my-assets');
                        }, 5000);
                        setShowLoader(false);
                        Toast.set({
                            show: true,
                            desc: 'Congratulations! Your skins have been successfully traded for a fantastic Pet.',
                            type: 'success',
                        });
                    } else {
                        setTimeout(() => {
                            navigate('/my-assets');
                        }, 5000);
                        setShowLoader(false);
                        Toast.set({
                            show: true,
                            desc: 'Oops! Trade process failed. Please contact us using discord. CODE: 002',
                            type: 'error',
                        });
                    }
                };
                await checkStatus();
            } catch (error) {
                console.error('Error:', error);
                setShowLoader(false);
                setTimeout(() => {
                    navigate('/my-assets');
                }, 5000);
                Toast.set({
                    show: true,
                    desc: 'Something went wrong. Please contact us using discord. CODE: 003',
                    type: 'error',
                });
            }
        };

        await tradeSkins();
    };

    useEffect(() => {
        const user = state?.userWallet?.string;
        const skinCollection = ['0x8a2315e441786e10ff1962387407628108b9ba33'];

        const updateAssets = (newAssets) => {
            const bundleName = currentItem.name.split(' ').slice(0, -1).join(' ');
            const bundleSkinsNfts = newAssets.filter(item => item.metadata?.bundle_name === bundleName);
            processCarouselData(bundleSkinsNfts);
            return bundleSkinsNfts;
        };

        const fetchSkins = async (walletAddress) => {
            await immutableService.getAssetsFromCollections(walletAddress, skinCollection, updateAssets);
        };

        if (user !== undefined && user !== null) {
            fetchSkins(user).then(() => { });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentItem]);

    return (
        <div className="bundle-container">
            <div className="bundle-top-segment">
                <div className="bundle-left-part">
                    <h1>{currentItem.name}</h1>
                    <p className="hide-on-small-screen">
                        To complete your bundle and unlock the special pet reward. Collect skins from multiple bundles to complete a full collection.
                        Once you have the entire collection, trade it in to receive your exclusive pet companion as a testament
                        to your dedication!
                    </p>
                    {selectedNFTs.length === 5 && <button className='btnTransparent' onClick={handleBurnSkins}>Trade For Pet</button>}
                </div>
                <div className="bundle-right-part">
                    <button className="carousel-button prev" onClick={handlePrevious}>←</button>
                    <img
                        src={currentItem.img}
                        alt={currentItem.name}
                        className="bundle-bordered-image"
                    />
                    <button className="carousel-button next" onClick={handleNext}>→</button>
                </div>
            </div>
            {showLoader && (
                <div className="trade-loader-container">
                    <div className="trade-loader-inner-container">
                        <ForgeLoader />
                        <h2 style={{ margin: '10px 0' }}>Burning the skins</h2>
                        <h2 style={{ margin: '10px 0' }}>Using the ashes to... get a Pet?</h2>
                    </div>
                </div>
            )}
            {showPopup ? (
                <Popup
                    onClose={() => setShowPopup(false)}
                    title="Confirmation required"
                    content={
                        <>
                            <p>
                                Are you sure you want to send your skins to the forge? You cannot
                                unbreak the broken, but you will get a pet :D
                            </p>
                            <Button label="Yes, Please" onClick={() => verify()} />
                            <span style={{ width: 10, display: "inline-block" }} />
                            <Button transparent label="No" onClick={() => setShowPopup(false)} />
                        </>
                    }
                />
            ) : null}
            <div className="bundle-bottom-segment">
                <div className="bundle-static-images">
                    {carouselData.map((item, index) => (
                        <div className="bundle-static-image-item" key={index}>
                            <img
                                src={item.image}
                                alt={item.name}
                                className={item.skinsAmount === 0 ? "grey-filter" : ""}
                            />
                            <div className="bundle-image-number">{item.skinsAmount}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MyBundles;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ElixirLogs = () => {
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [stat, setStat] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, stat]);

    const fetchData = async () => {
        try {
            if (userId && stat) {
                const response = await axios.get(`https://api.qorbiworld.com/v1/filter-log-events?userId=${userId}&stat=${stat}`);
                setLogs(response.data);
            } else {
                const response = await axios.get(`https://api.qorbiworld.com/v1/log-events?page=${currentPage}`);
                setLogs(response.data.results);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handlePageChange = (direction) => {
        setCurrentPage(prevPage => prevPage + direction);
    };

    const handleStatChange = (event) => {
        setStat(event.target.value);
    };

    const handleUserIdChange = (event) => {
        setUserId(event.target.value);
    };

    const handleSearch = () => {
        fetchData();
    };

    return (
        <div className="elixir-logs-container">
            <div className="search-container">
                <input
                    type="text"
                    placeholder="User ID"
                    value={userId}
                    onChange={handleUserIdChange}
                    className="search-input"
                />
                <select value={stat} onChange={handleStatChange} className="search-select">
                    <option value="">Select Stat</option>
                    <option value="headshots">Headshots</option>
                    <option value="completed_game">Completed Game</option>
                    <option value="flawless_match">Flawless Match</option>
                    <option value="type_round_won">Type Round Won</option>
                    <option value="shotgun_kill">Shotgun Kill</option>
                    <option value="asault_rifle_kill">Assault Rifle Kill</option>
                    <option value="sniper_kill">Sniper Kill</option>
                    <option value="knife_kill">Knife Kill</option>
                    <option value="revolver_kill">Revolver Kill</option>
                    <option value="level_up">Level Up</option>
                    <option value="new_xp">New XP</option>
                </select>
                <button onClick={handleSearch} className="search-button">Search</button>
            </div>
            <table className="logs-table">
                <thead>
                    <tr>
                        <th>Stat</th>
                        <th>User ID</th>
                        <th>Game ID</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                    </tr>
                </thead>
                <tbody>
                    {logs && logs.map((log, index) => (
                        <tr key={index}>
                            <td>{log.stat}</td>
                            <td>{log.userId}</td>
                            <td>{log.gameId}</td>
                            <td>{log.createdAt}</td>
                            <td>{log.updatedAt}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="pagination">
                {currentPage !== 1 && <button onClick={() => handlePageChange(-1)}>Previous</button>}
                {logs.length >= 30 && <button onClick={() => handlePageChange(1)}>Next</button>}
            </div>
        </div>
    );
};

export default ElixirLogs;

import React from 'react';
import { useNavigate } from 'react-router-dom';

const ConnectWalletToBuy = () => {
    const navigate = useNavigate();

    const login = () => {
        const currentPathWithQuery = window.location.pathname + window.location.search;
        localStorage.setItem('unauthorizedPath', `${currentPathWithQuery}`)
        navigate('/profile')
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
            }}
        >
            <div
                style={{
                    background: '#1E1E1E',
                    padding: '20px',
                    borderRadius: '15px',
                    textAlign: 'center',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.7)',
                    animation: 'fadeIn 0.3s',
                }}
            >
                <p style={{ fontSize: '24px', color: '#25C486', fontWeight: 'bold', marginBottom: '10px' }}>Alert!</p>
                <p style={{ fontSize: '18px', color: 'white', marginBottom: '20px' }}>Please connect your wallet to continue</p>
                <button
                    onClick={() => login()}
                    style={{ backgroundColor: '#25C486', color: 'white', border: 'none', borderRadius: '5px', padding: '12px 24px', fontSize: '18px', cursor: 'pointer', transition: 'background-color 0.3s' }}
                    onMouseOver={(e) => e.target.style.backgroundColor = '#1E1E1E'}
                    onMouseOut={(e) => e.target.style.backgroundColor = '#25C486'}
                >
                    Login and Connect
                </button>
            </div>
        </div >
    );
}

export default ConnectWalletToBuy;
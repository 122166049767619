import React, { useState, useEffect } from 'react';

const BundleHomePromotion = () => {
    const imagesArray = [
        { image: "https://digital.qorbiworld.com/Bundle+Skins+-+Ally.jpg", legend: "Ally" },
        { image: "https://digital.qorbiworld.com/Bundle+Skins+-+Altru.jpg", legend: "Altru" },
        { image: "https://digital.qorbiworld.com/Bundle+Skins+-+Atomic.jpg", legend: "Atomic" },
        { image: "https://digital.qorbiworld.com/Bundle+Skins+-+Forge.jpg", legend: "Forge" },
        { image: "https://digital.qorbiworld.com/Bundle+Skins+-+Hyper.jpg", legend: "Hyper" },
        { image: "https://digital.qorbiworld.com/Bundle+Skins+-+Mon.jpg", legend: "Mon" },
        { image: "https://digital.qorbiworld.com/Bundle+Skins+-+OneTap.jpg", legend: "One Tap" },
        { image: "https://digital.qorbiworld.com/Bundle+Skins+-+QorGaming.jpg", legend: "Qor Gaming" },
        { image: "https://digital.qorbiworld.com/Bundle+Skins+-+QorbiWorld.jpg", legend: "Qorbi World" },
        { image: "https://digital.qorbiworld.com/Bundle+Skins+-+Xis.jpg", legend: "Xis" },
    ];
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % imagesArray.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + imagesArray.length) % imagesArray.length);
    };

    useEffect(() => {
        const interval = setInterval(nextSlide, 2000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="bundle-promotion-container">
            <div className="bundle-promotion-content">
                <div className="bundle-promotion-carousel-container">
                    <button className="bundle-promotion-carousel-button prev" onClick={prevSlide}>←</button>
                    <div className="bundle-promotion-carousel-slide">
                        <img src={imagesArray[currentSlide].image} alt={imagesArray[currentSlide].legend} />
                        <p className="bundle-promotion-legend">{imagesArray[currentSlide].legend}</p>
                    </div>
                    <button className="bundle-promotion-carousel-button next" onClick={nextSlide}>→</button>
                </div>
                <div className="bundle-promotion-text">
                    <h2 className="bundle-promotion-promo-title">Discover Our Exclusive Bundles</h2>
                    <p className="bundle-promotion-promo-description">
                        One Tap is proud to present to you the new Skin Bundles. Collect them all and get a brand-new bonus pet. These skins and pets are exclusive to the bundle system, with high rarity values, and built in pledging rewards. Each bundle purchase has 3 skins for only $3.00
                    </p>
                    <div className="bundle-promotion-bundle-benefits">
                        <h3>Why Purchase Our Bundles?</h3>
                        <ul>
                            <li>Exclusive in-game pets and skins</li>
                            <li>Access to premium quests</li>
                            <li>Special rewards and bonuses</li>
                        </ul>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10, position: "relative", left: 0, width: "100%", paddingLeft: 20, paddingRight: 20 }}>
                        <button
                            className="greenBtn"
                            style={{ width: "100%", marginRight: "10px", height: 50 }}
                            onClick={() => window.open("https://qorbiworld.com/single/1", "_blank")}
                        >
                            Buy Now
                        </button>
                        <button
                            className="greenBtn"
                            style={{ width: "100%", marginRight: "10px", height: 50 }}
                            onClick={() => window.open("https://qorbiworld.medium.com/discover-the-exciting-skin-bundles-from-qorbi-world-4a34eb223968", "_blank")}
                        >
                            Learn More
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BundleHomePromotion;

import React from "react";
import { Banner } from "../components/Banner";

const Qorbi = () => {
  return (
    <div className="darkbg">
      <Banner
        //imgURL="https://imagedelivery.net/iqzXzl6cKZZq6VjsnAU2MA/e9280470-8872-4017-3c93-5cb56947af00/public"
        videoURL="https://digital.qorbiworld.com/qorbi_coin+promo.mp4"
        title={<>$Qorbi</>}
        //extraText={this.context.state.babiesNumber > 0 ? this.context.state.babiesNumber + ' Babies have found their Owners! PRESALE SOLD OUT' : ''}
        subtitle={
          <>
            {" "}
            Qorbi Coin is the exclusive currency of the Qorbi World games. Use
            Qorbi Coin to purchase in game items, participate in DAO voting, and
            continues rewards.
          </>
        }
        buttons={<></>}
      />
    </div>
  );
};

export default Qorbi;

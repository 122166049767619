import React, { useState, useContext, useEffect } from 'react';
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import { Context } from "../context/WalletContext";
import { ImmutablePassport } from "../utils/Global";
import { passportInstance } from '../context/PassportService';

const qorbiServices = require('../context/QorbiService');

const RegisterForm = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const walletAddress = localStorage.getItem("WALLET_ADDRESS");
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const { logOut, state } = useContext(Context);
    const { setRegisterUser, setStartMission, setQorbiUserData } = useSphereOneProvider();

    const handleChangeUsername = (e) => {
        const value = e.target.value;
        if (!/\s/.test(value)) {
            setUsername(value);
        }
    };

    const handleChangeEmail = (e) => {
        const value = e.target.value;
        if (!/\s/.test(value)) {
            setEmail(value);
        }
    };

    const handleChangeReferralCode = (e) => {
        const value = e.target.value;
        if (!/\s/.test(value)) {
            setReferralCode(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!username || !email) {
            setErrorMessage('Please fill in all the required fields.');
            return;
        }

        const addUser = async (userData) => {
            try {
                await qorbiServices.createQorbiUser(userData);
                setRegisterUser(false);
                setStartMission(true);
                setQorbiUserData(userData);
            } catch (addUserError) {
                console.error('Error adding user:', addUserError);
                setErrorMessage(addUserError.response?.data?.message || 'An error occurred.');
            }
        };
        const userData = {
            username: username,
            walletId: walletAddress,
            email: email,
            referedCode: referralCode,
        };
        await addUser(userData);
    };

    const getPassportEmail = async () => {
        const userProfile = await passportInstance.getUserInfo();
        setEmail(userProfile.email)
    }

    useEffect(() => {
        if (state?.user?.authType === ImmutablePassport) {
            getPassportEmail()
        }
    }, [state]);

    return (
        <div className="main-overlay">
            <div className='black-overlay' style={{ width: !isMobile && "30%" }}>
                <h2 style={{ color: "white" }}>Create Your Account</h2>
                {errorMessage && <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="register-form-group">
                        <label htmlFor="username" style={{ color: "white", display: "block", textAlign: "left", marginLeft: 10 }}>Username:</label>
                        <input
                            style={{ backgroundColor: "white" }}
                            className='register-form-input'
                            type="text"
                            id="username"
                            placeholder="Ex: CoolQorUser"
                            value={username}
                            onChange={handleChangeUsername}
                            required
                        />
                    </div>
                    {state?.user?.authType !== ImmutablePassport && (
                        <div className="register-form-group">
                            <label htmlFor="email" style={{ color: "white", display: "block", textAlign: "left", marginLeft: 10 }}>Email:</label>
                            <input
                                className='register-form-input'
                                type="email"
                                id="email"
                                placeholder="Ex: cool@qor.user"
                                value={email}
                                onChange={handleChangeEmail}
                                required
                            />
                        </div>
                    )}
                    <div className="register-form-group">
                        <label htmlFor="referralCode" style={{ color: "white", display: "block", textAlign: "left", marginLeft: 10 }}>Referral Code:</label>
                        <input
                            className='register-form-input'
                            type="referral"
                            id="referralCode"
                            placeholder="Optional"
                            value={referralCode}
                            onChange={handleChangeReferralCode}
                        // required
                        />
                    </div>
                    <button className='register-form-button' type="submit">Register</button>
                </form>
                <button
                    style={{ marginTop: 15, backgroundColor: "red", transition: "background-color 0.3s" }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = "#B80000"}
                    onMouseLeave={(e) => e.target.style.backgroundColor = "red"}
                    className='register-form-button'
                    onClick={() => {
                        logOut();
                    }}>
                    Cancel Register
                </button>
            </div>
        </div>
    );
};

export default RegisterForm;

export const getZkEvmAssets = async (walletAddress) => {
    const nameToCollectionMap = {
        "QorbiWorld Pets": "pet",
        "One Tap Badges": "badge",
        "Qor-X Passes": "pass",
        "Qorb Collection": "qorb",
        "One Tap Weapon Skins Color Series": "skin",
    };

    const baseUrl = `https://immutable-mainnet.blockscout.com/api/v2/addresses/${walletAddress}/nft?type=`;

    const fetchAllNFTs = async (nextPageParams = null, accumulatedNFTs = []) => {
        let url = baseUrl;
        if (nextPageParams) {
            const queryParams = new URLSearchParams({
                items_count: nextPageParams.items_count,
                token_contract_address_hash: nextPageParams.token_contract_address_hash,
                token_id: nextPageParams.token_id,
                token_type: nextPageParams.token_type,
            });
            url += `&${queryParams.toString()}`;
        }

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Error fetching data: ${response.statusText}`);
            }

            const data = await response.json();
            const items = data.items || [];
            const updatedAccumulatedNFTs = [...accumulatedNFTs, ...items];
            if (data.next_page_params) {
                return fetchAllNFTs(data.next_page_params, updatedAccumulatedNFTs);
            }

            return updatedAccumulatedNFTs;
        } catch (error) {
            console.error("Error fetching zkEVM assets:", error);
            return accumulatedNFTs;
        }
    };

    try {
        const allNFTs = await fetchAllNFTs();

        const nftMetadataPromises = allNFTs
            .filter((token) => nameToCollectionMap[token.token.name])
            .map(async (token) => {
                const collection = nameToCollectionMap[token.token.name];
                const tokenId = token.id;
                try {
                    const metadataResponse = await fetch(
                        `https://assets.qorbiworld.com/resource/${collection}/${tokenId}`
                    );
                    if (!metadataResponse.ok) {
                        throw new Error(
                            `Error fetching metadata for token ${tokenId}: ${metadataResponse.statusText}`
                        );
                    }
                    const metadata = await metadataResponse.json();
                    return {
                        ...token,
                        metadata,
                    };
                } catch (error) {
                    console.error(
                        `Error fetching metadata for token ${tokenId} in collection ${collection}:`,
                        error
                    );
                    return null;
                }
            });

        const nftsWithMetadata = (await Promise.all(nftMetadataPromises)).filter(Boolean);
        return nftsWithMetadata;
    } catch (error) {
        console.error("Error fetching zkEVM assets:", error);
        return [];
    }
};
import React, { useContext, useState, useEffect } from "react";
import { useSphereOneProvider } from "../../utils/SphereOneHooks";
import { Link, useLocation } from "react-router-dom";
import { Context } from "../../context/WalletContext";

export const MenuNoLogin = ({ itemLocation }) => {
  let location = useLocation();
  const { state } = useContext(Context);
  const [showBeta, setShowBeta] = useState(false);

  const { qorbiUserData } = useSphereOneProvider();

  const menu = [
    { name: "Home", path: "/", type: "internal" },
    { name: "Games", path: "/games", type: "internal" },
    { name: "White Paper", path: "https://whitepaper.qorbiworld.com/", type: "external" },
    { name: "Market", path: "https://market.qorbiworld.com/explore", type: "external" },
    { name: "Ranking", path: "/leaderboard", type: "internal" },
    { name: "Skin Forge", path: "/skin-forge", type: "internal", banned: "footer" },
    { name: "My Assets", path: "/my-assets", type: "internal", banned: "footer" },
    { name: "My Bundles", path: "/bundles", type: "internal", banned: "footer" }
  ];

  useEffect(() => {
    if (qorbiUserData?.testerGroup) {
      setShowBeta(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qorbiUserData]);

  if (showBeta) {
    menu.push({ name: "Beta Testers", path: "/beta-tester", type: "internal", banned: "navbar" });
  }
  const filteredMenu = menu.filter(item => item.banned !== itemLocation);

  return (
    <>
      {filteredMenu.map((item, index) => (
        <li className="nav-item dropdown" key={index}>
          {item.type === "external" ? (
            <a
              href={item.path}
              target="_blank"
              className={`nav-link ${location.pathname === item.path ? "active" : ""}`}
              rel="noreferrer"
            >
              {item.name}
            </a>
          ) : (
            <Link
              className={`nav-link ${location.pathname === item.path ? "active" : ""}`}
              to={item.path}
            >
              {item.name}
              {item.subpages ? <i className="fas fa-angle-down ml-1" /> : null}
            </Link>
          )}
          {item.subpages ? (
            <ul className="dropdown-menu">
              {item.subpages.map((item2, index2) => (
                <li className="nav-item" key={index2}>
                  {item2.type === "external" ? (
                    <a
                      href={item2.path}
                      target="_blank"
                      className={`nav-link ${location.pathname === item.path ? "active" : ""}`}
                      rel="noreferrer"
                    >
                      {item2.name}
                    </a>
                  ) : (
                    <Link
                      to={item2.path}
                      className={`nav-link ${location.pathname === item2.path ? "active" : ""}`}
                    >
                      {item2.name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          ) : null}
        </li>
      ))}
      {state.marketplaceStatus && state.marketplaceStatus.mintEnable ? (
        <li className="nav-item dropdown">
          <Link
            className={`nav-link ${location.pathname === "/mint" ? "active" : ""}`}
            to="/mint"
          >
            Mint
          </Link>
        </li>
      ) : null}
    </>
  );
};
